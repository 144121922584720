/*------------------
      Variants
-------------------*/

/*-- Small --*/

.ui.segment.-small {
  display: inline-block;
  padding: 0.5em;
}

/*-- Borderless --*/

.ui.segment.-borderless {
  border-radius: 0;
  border: none;
}

/*-- Borderless --*/

.ui.segment.-padless {
  padding: 0;
}

/*------------------
      Header
      segment
-------------------*/

.header-segment {
  @include classic-box-shadow;

  > .ui.header.attached {
    background-color: $moodySky;
    border: none;
    color: white;
    font-size: 1.33em;
  }

  > .ui.header,
  > .ui.segment {
    border: none;
  }
}

/*-- Sectionned --*/
.header-segment.-sectionned > .ui.segment {
  padding: 0;

  > .section {
    border-bottom: solid 2px tint(#dbdbdb, 50%);
    padding: 2em 5%;
  }

  > .section:last-child {
    border-bottom: none;
  }

  > .section > .video-container {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}

/*-- Side menu --*/

.header-segment.-sidemenu > .segment {
  display: flex;
  flex-direction: row;
  padding: 0;
  $vPadding: 1.5em;
  $hPadding: 0.5em;

  > .menu {
    flex: 0 0 auto;
    padding: $vPadding 0.35714286em + $hPadding;
  }

  > .content {
    flex: 1 1 auto;
    padding: $vPadding $hPadding;
  }
}
