.ui.button.-block {
  border-radius: 0;
  height: 100%;
}

/* Button list */
.button-list {
  align-items: center;
  display: inline-flex;
  $margin: 5px;
  margin-bottom: -$margin;

  > .ui.button {
    margin-right: 15px;
    margin-bottom: $margin;
  }
}

.ui.header > .button-list,
.ui.header > .content > .button-list {
  margin-left: 40px;
}

.ui.header > .ui.button,
.ui.header > .content > .ui.button {
  margin-left: 20px;
}

.button-list {
  &.-block {
    display: flex;
  }
}

.button-list,
.ui.button {
  &.-spaced {
    margin-bottom: 15px;
  }
}

.ui.button.-tertiary,
.ui.form .field.-tertiary > .ui.button {
  @include button-tertiary;

  &.primary {
    @include button-tertiary($daringBlue);
  }

  &.positive,
  &.green {
    @include button-tertiary($aquamarine);
  }

  &.negative,
  &.red {
    @include button-tertiary($bloodOrange);
  }
}

.button.-semantic {
  background: none;
  border: none;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }
}

@mixin button {
  display: inline-block;

  min-height: 1em;

  outline: none;
  border: none;
  background: $paleSilver;
  color: $moodySky;

  font-family: $headerFont;

  margin: 0em 0.25em 0em 0em;
  padding: $relative11px 2em;

  font-weight: bold;
  font-style: normal;
  text-align: center;
  text-decoration: none;

  border-radius: $defaultBorderRadius;

  /* Internal Shadow */
  $shadowDistance: 0;
  $shadowOffset: ($shadowDistance / 2);
  $shadowBoxShadow: 0px -$shadowDistance 0px 0px $borderColor inset;

  /* Box Shadow */
  $borderBoxShadowColor: $silver;
  $borderBoxShadowWidth: 1px;
  $borderBoxShadow: 0px 0px 0px $borderBoxShadowWidth $borderBoxShadowColor
    inset;
  box-shadow: $borderBoxShadow, $shadowBoxShadow;

  user-select: none;
  transition: opacity $defaultTransitionDuration $defaultTransitionEase,
    background-color $defaultTransitionDuration $defaultTransitionEase,
    color $defaultTransitionDuration $defaultTransitionEase,
    box-shadow $defaultTransitionDuration $defaultTransitionEase,
    background $defaultTransitionDuration $defaultTransitionEase;
}
