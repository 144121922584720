.button-switch {
  background-color: white;
  border: 1px solid $silver;
  border-radius: $defaultBorderRadius;
  padding: 2px;

  > .button {
    border-radius: $defaultBorderRadius !important;
    box-shadow: none !important;
    font-weight: normal;
    padding: 0.6em 0.3em;
    min-width: 4em;
  }

  > .button:not(.active) {
    background: transparent;
    box-shadow: none !important;
  }
}
