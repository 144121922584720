.floating-message-list {
  @include reset-list;

  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 600px;
  z-index: 20;
}
