.home-page {
  position: relative;
  height: 100%;
  padding-bottom: $headerbar-height;
}

.home-page > .golden-container {
  align-items: center;
  height: 100%;
}
