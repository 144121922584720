.grid-container {
  @include reset-list;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > .row {
    @include reset-list;
    align-items: stretch;
    display: flex;
    justify-content: space-between;
  }

  > .row > .item {
    flex: 1 0;
  }
}
