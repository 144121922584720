@mixin link() {
  color: $azul;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.2s ease, background-color 0.2s ease;

  &:hover {
    color: tint($azul, 30%);
  }

  &.-block {
    color: inherit;
    display: block;
    width: 100%;
  }

  &.-block:hover {
    background-color: transparentize(white, $amount: 0.7);
  }

  &.-semantic {
    display: inline-block;
    color: $textColour;
    height: 100%;
    width: 100%;
  }

  &.-active {
    color: $lightGold;
  }
}

@mixin button-tertiary($colour: $moodySky) {
  background: none;
  box-shadow: none;
  color: tint($colour, 30%);
  text-shadow: none;

  &:hover {
    background: none;
    box-shadow: none;
    color: $colour;
    text-shadow: none;
  }
}

@mixin pop-text {
  text-shadow: $popTextShadow;
}

@mixin classic-box-shadow() {
  box-shadow: 0 2px 16px 0 $elevatedShadowColour;
}

@mixin soft-elevated-shadow() {
  box-shadow: 0 1px 8px 0 $elevatedShadowColour;
}

@mixin subtle-shadow() {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
