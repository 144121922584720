.video-container {
  position: relative;

  > .video {
    width: 100%;
  }

  > .playbutton {
    align-items: center;
    display: flex;
    justify-content: center;
    opacity: 0.6;
    position: absolute;
    top: 0;
    bottom: 0;
    transition: opacity $defaultTransitionDuration $defaultTransitionEase;
    width: 100%;
    z-index: 5;
  }

  > .mutebutton {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 1.75em;
    left: 1.75em;
    transition: opacity $defaultTransitionDuration $defaultTransitionEase,
      visibility $defaultTransitionDuration $defaultTransitionEase;
    z-index: 6;
  }
}

.video-container.-playing {
  > .playbutton {
    opacity: 0;
  }

  > .mutebutton {
    opacity: 1;
    visibility: visible;
  }
}

.video-container.-square {
  align-items: center;
  display: flex;
  overflow: hidden;
}
