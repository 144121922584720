$sidebar-width: 230px;
$headerbar-height: 70px;

// Flexbox fallback (boooh you did not update your browser!!!)
.sidebar-layout {
  display: flex;
  min-height: 100%;

  > .sidebar-container {
    flex: 0 0 $sidebar-width;
  }

  > .sidebar-container > .side-bar {
    height: 100%;
    position: fixed;
    width: $sidebar-width;
    z-index: 25;
  }

  > .body-container {
    flex: 1 1 auto;
  }
}

.headerbar-layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  > .headerbar-container {
    flex: 0 0 $headerbar-height;
    margin-bottom: 0;
  }

  > .headerbar-container > .header-bar {
    height: $headerbar-height;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 50;
  }

  > .main-container {
    flex: 1 1 auto;
    padding: 30px 5%;
  }
}

.sidebar-layout > .body-container > .headerbar-layout {
  > .headerbar-container > .header-bar {
    left: $sidebar-width;
  }
}

// Grid extreme cutting edge CSS technology!!
.sidebar-layout {
  display: grid;
  grid-template-rows: $headerbar-height 1fr;
  grid-template-columns: $sidebar-width 1fr;
  grid-template-areas:
    'sidebar body'
    'sidebar body';

  > .sidebar-container {
    grid-area: sidebar;
  }

  > .body-container {
    grid-area: body;
  }
}

.headerbar-layout {
  display: grid;
  grid-template-rows: $headerbar-height 1fr;
  grid-template-columns: 100%;
  grid-template-areas:
    'header header'
    'body body';

  > .header-bar {
    grid-area: header;
  }

  > .main-container {
    grid-area: body;
  }
}
