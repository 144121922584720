$uiCellPadding: 0.78571429em;

.ui.table tr td {
  // This is kind of hack to make the link cover the surface of the <td>
  &.-link > .link {
    box-sizing: content-box;
    margin: -$uiCellPadding;
    padding: inherit;
    width: 100%;
  }
}

@mixin paddingOnSide {
  $padding: $uiCellPadding + 2em;

  &:first-child {
    padding-left: $padding;
  }

  &:last-child {
    padding-right: $padding;
  }

  &.-link:first-child > .link {
    margin-left: -$padding;
  }

  &.-link:last-child > .link {
    margin-left: -$padding;
  }
}
