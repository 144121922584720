$badgeHeight: 50px;
$mBadge: $badgeHeight / 10;

.badge-list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: -$mBadge;
}

.badge-list > .badge {
  margin: $mBadge;
  height: $badgeHeight;
}

.badge-list > .badge > .image {
  height: 100%;
}

.badge-list.-small {
  $badgeHeight: 40px;
  $mBadge: $badgeHeight / 10;

  margin: -$mBadge;

  > .badge {
    margin: $mBadge;
    height: $badgeHeight;
  }
}
