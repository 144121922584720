$defaultBorderRadius: 2px;

// Semantic
$mobileBreakpoint: 320px;
$tabletBreakpoint: 768px;
$computerBreakpoint: 992px;
$largeMonitorBreakpoint: 1200px;
$widescreenMonitorBreakpoint: 1920px;

$relative11px: 0.78571429em;
