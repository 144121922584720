/*------------------
      Forms
-------------------*/

.ui.form.-vcentre > .fields {
  > .field {
    align-items: center;
    display: flex;
  }

  &.inline > .field {
    display: inline-flex;
  }
}

.ui.form > .fields > .field.-fluid {
  flex: 1 1 auto;

  > .ui.input {
    max-width: none;
  }
}

/*------------------
      Variations
-------------------*/

/*-- Stacked --*/

$lMargin: 1em;
$labelSize: 34%;
$inputSize: 66%;
.ui.form.-stacked > .formrow {
  align-items: center;
  display: flex;
  margin-bottom: 1em;

  > label {
    font-weight: 700;
    font-size: 0.92857143em;
    flex: 0 0 $labelSize;
    margin: 0;
    text-align: right;
  }

  > .input,
  > .inputs,
  > .dropdown,
  > textarea {
    margin-left: $lMargin;
    flex: 1 1 $inputSize;
  }

  > .button {
    margin-left: calc(#{$labelSize} + #{$lMargin});
  }

  // Multiple inputs
  > .inputs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: -0.5em -0.5em -0.5em $lMargin - 0.5em;
  }

  > .inputs > .input {
    flex: 1 1;
    margin: 0.5em;
  }
}

/*-- Simple --*/

@mixin input-simple-var {
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

.ui.form.-simple input[type='text'] {
  @include input-simple-var();
}

/*------------------
      Inputs
-------------------*/

/*-- Simple --*/

.ui.input,
.ui.search {
  &.-simple > input {
    @include input-simple-var();
  }
}

/*-- Basic --*/
.ui.input.-basic {
  > input {
    background-color: transparent;
    box-shadow: none;
  }
}
