$border: 1px solid $cloudySky;

.side-bar {
  background-color: white;
  border-right: $border;
  display: flex;
  flex-direction: column;
  padding: 20px 25px;

  > .section {
    margin-top: 60px;
  }

  > .ui.menu.misc-menu {
    margin-top: auto;
  }
}

.header-bar.menu {
  background-color: white;
  border-radius: 0;
  border: none;
  border-bottom: $border;
  box-shadow: none;

  > .item > .usersearch {
    max-width: 500px;
    width: 100%;

    > .input {
      width: 100%;
    }
  }

  // Notification badge
  > .menu > .notificationitem > .ui.label {
    top: 1em;
    left: 80%;
  }
}
