button.link,
button.semantic-link {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;

  &:active {
    outline: false;
  }

  &:focus {
    outline: none;
  }
}

.link {
  @include link;
}

.navigation-link {
  display: inline-block;
  padding: 5px 0;
}

.semantic-link {
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.link,
.semantic-link {
  &.-icon {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }

  &.-icon > .icon:only-child {
    margin: 0;
  }

  &.-floatright {
    float: right;
  }
}

.link.-tertiary {
  @include button-tertiary();
  padding: 0;
}
