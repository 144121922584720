$defaultTransitionDuration: 0.2s;
$defaultTransitionEase: ease;

@mixin sprite-animation($imageUrl, $size, $nbSteps, $time) {
  background: url($imageUrl) no-repeat;
  background-size: auto $size;
  background-position: 0 0;
  height: $size;
  width: $size;

  &.-animate {
    transition: background $time steps($nbSteps - 1);
  }

  &.-animated {
    background-position: ($nbSteps - 1) * -$size;
  }
}

@mixin animation-fist-bump($size: 100px, $time: 0.5s) {
  @include sprite-animation(
    '../assets/icons/flexpit/FistbumpSprite.png',
    $size,
    28,
    $time
  );
}
