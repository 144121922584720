// Admin design colours TODO: remove
$azul: #1f53ea;
$moodySky: #242d3d;
$midnightBlue: #0c0033;
$blueBlue: #143dcc;
$bluePurple: #4000ff;
$lightGold: #ffc754;
$aquamarine: #00d3a7;
$bloodOrange: #ff443a;
$whitey: #f0f0f0;

$cloudySky: #c9c9c9;
$grey: tint($midnightBlue, 50%);
$silver: tint($midnightBlue, 75%);
$lightSilver: tint($midnightBlue, 90%);
$veryLightSilver: tint($midnightBlue, 96%);

$textColour: $midnightBlue;

// Utils
$popTextShadow: 0 1px 2px rgba(0, 0, 0, 0.2);

/*-------------------
    Brand Colors
--------------------*/

$anthracite: #353535;
$silver: #e5e5e5;
$paleSilver: #f3f4f6;
$whitey: #f4f5f7;
$daringBlue: #1c2ddb; // #activeButton on Zeplin
$electricBlue: #3444e9; // #buttonHover on Zeplin
$softBlue: #1e54ea; // #activeItem on Zeplin
$seaBlue: #111170; // Hovering a link
$moodySky: #2a3852;
$gold: #ffc654;
$aquamarine: #00d3a7;
$bloodOrange: #ff443a;
$steelGrey: #7e7f80;

// Shades of grey
$warmGrey: #9a9a9a;

$elevatedShadowColour: rgba(175, 175, 175, 0.2);

$borderColor: $silver;
