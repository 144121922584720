.ui.input {
  &.-megafluid {
    max-width: none;
  }

  &.-rounded > input {
    border-radius: 17.5px;
  }

  &.-borderless > input {
    border: none;
  }
}
