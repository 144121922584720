// Card and grid styling
$cardSize: 150px;
$bRadius: 10px;

.activity-card {
  border-radius: $bRadius;
  height: $cardSize;
  width: $cardSize;
  transition: 0.15s box-shadow;

  > .name {
    transition: 0.2s color;
  }

  &:hover {
    @include classic-box-shadow();
    transition-duration: 0.3s;
  }

  &:hover > .name {
    color: $daringBlue;
  }
}

.activity-grid > .activity-list {
  $item-m: 20px;
  @include reset-list;

  align-content: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -$item-m;

  > .activity-item {
    margin: $item-m;
  }
}

// Card styling using flexbox as a fallback
$nbCols: 3;
$colSize: $cardSize / 3;
$nameH: 19px;

.activity-card {
  align-items: center;
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > .image {
    width: $colSize !important;
  }

  > .name {
    $nameMV: ($colSize - $nameH) / 2;
    margin: $nameMV 0;
  }
}

// Card styling using latest grid!!!
.activity-card {
  display: grid;
  grid-template-columns: repeat($nbCols, 1fr);
  grid-template-rows: repeat($nbCols, 1fr);
  grid-template-areas:
    '. . .'
    '. image .'
    'header header header';

  > .image {
    grid-area: image;
  }

  > .name {
    align-self: center;
    grid-area: header;
  }
}

@supports (display: grid) {
  .activity-card > .image {
    width: 100% !important;
  }

  .activity-card > .name {
    margin: 0;
  }
}
