.cropped-image {
  position: absolute;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;

  > img.image {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
