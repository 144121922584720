.about-link-list {
  align-items: center;
  display: flex;
  list-style: none;
  margin: -10px;
  padding: 0;

  > .about-link-item {
    margin: 10px;
  }
}

.about-link-item > .link {
  font-weight: bold;
  text-transform: uppercase;
}
