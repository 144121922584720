.user-profile {
  padding-top: 20px;

  > .content-container > .userheader {
    margin-bottom: 80px;
  }
}

.user-header {
  .username > .verifiedicon {
    height: 16px;
    vertical-align: top;
    width: auto;
  }

  .list.details {
    margin-bottom: 1em;
  }
}
