/*------------------
    Notification
    popup
--------------------*/

.notifications-popup {
  $popupWidth: 360px;
  $popupHeight: 250px;
  background-color: white;
  display: none;
  height: $popupHeight;
  position: fixed;
  top: $headerbar-height;
  right: 50px;
  @include subtle-shadow;
  width: $popupWidth;
  z-index: 100;

  &.-open {
    display: block;
  }
}

/*------------------
    Notification
    feed
--------------------*/

.notifications-popup > .notification-feed {
  height: 100%;
  width: 100%;
}

.notification-feed > .event-notification.-segment {
  .content > .summary > .information-block > .flexpit-svg-icon {
    margin: -0.15em 0.5em 0 0;
    height: 1em;
  }
}

/*------------------
    Notification
    block
--------------------*/

/*-- Layout --*/

.event.event-notification {
  height: 72px;

  > .content > .summary {
    font-family: $headerFont;
    font-weight: 300;
  }

  > .content > .summary > .author {
    font-weight: bold;
  }

  > .endcontent {
    align-self: center;
    margin-left: auto;
    flex: 0 0 auto;
  }

  > .endcontent.image {
    width: 50px;
  }
}

/*-- Hover --*/

a.event.event-notification {
  transition: color $defaultTransitionDuration $defaultTransitionEase;

  &:hover > .content > .summary {
    color: $daringBlue;
  }
}

//*-- Unseen --*/

.event.event-notification.-unseen {
  background-color: $whitey;
}
