$fontName: Nunito;
$headerFontName: Montserrat;
$pageFont: $fontName, 'Helvetica Neue', Arial, Helvetica, sans-serif;
$headerFont: $headerFontName, 'Helvetica Neue', Arial, Helvetica, sans-serif;

.strong-info {
  color: $moodySky;
  display: block;
  font-family: $headerFont;
  font-weight: bold;
}

.emphasised-info {
  font-style: normal;
  color: $daringBlue;
}

$textColor: $anthracite;
