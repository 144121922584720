.login-block > .flexpitlogo {
  max-width: 200px;
}

.login-block > .header {
  margin-top: 2.3em;
}

.login-block > .welcometext {
  color: tint($textColor, 15%);
  font-family: $headerFont;
  font-size: 1.1em;
  margin-bottom: 2.3em;
}

.login-block > .login-form {
  margin-bottom: 1.7em;
}
