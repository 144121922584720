.golden-a {
  width: 61.8%;
}

.golden-b {
  width: 38.2%;
}

.golden-container {
  display: flex;

  > .asection {
    flex: 0 0 61.8%;
  }

  > .bsection {
    flex: 0 0 38.2%;
  }
}

.golden-a,
.golden-b,
.golden-container > .asection,
.golden-container > .bsection {
  &.-padded {
    $sidePadding: 20px;
    padding-left: $sidePadding;
    padding-right: $sidePadding;
  }
}
