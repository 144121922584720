.ui.menu > .item {
  &.-fluid {
    flex: 1 1 auto;
  }

  &.-centre {
    justify-content: center;
  }

  &.-vertical {
    flex-direction: column;
  }
}
