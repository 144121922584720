/*------------------
    Post block
--------------------*/

/*-- Layout --*/

.post-block {
  display: flex;

  > .content,
  > .mediacontainer {
    flex: 0 0 auto;
    width: 50%;
  }
}

.post-block > .mediacontainer {
  > .media {
    height: 100%;
    width: 100%;
  }

  > .media > .verifiedstatus {
    background-color: white;
    border-radius: $defaultBorderRadius;
    color: #909090;
    font-family: $headerFont;
    font-size: 12px;
    font-weight: 500;
    opacity: 1;
    padding: 0.67em;
    position: absolute;
    top: 2em;
    right: 2em;
    transition: opacity $defaultTransitionDuration $defaultTransitionEase,
      visibility $defaultTransitionDuration $defaultTransitionEase;
    visibility: visible;
    z-index: 10;
  }

  > .media > .verifiedstatus > .flexpit-svg-icon {
    margin-right: 0.67em;
    vertical-align: text-top;
  }

  > .media > .verifiedstatus > .flexpit-svg-icon:last-child {
    margin-right: 0;
  }

  > .media.-playing > .verifiedstatus {
    opacity: 0;
    visibility: hidden;
  }
}

/*-- Content layout --*/

.post-block > .content {
  background-color: white;
  display: flex;
  flex-direction: column;
  $contentPadding: 10px;

  > .addcomment {
    margin-top: auto;
  }

  > .body {
    flex: 1 1 auto;
  }

  > .button-list,
  > .userheader,
  > .addcomment {
    flex: 0 0 auto;
  }

  > .body {
    padding: $contentPadding;
  }

  > .userheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: $contentPadding;
    padding-right: $contentPadding;
  }

  > .userheader > .header {
    margin-bottom: 0;
    padding-top: $contentPadding / 2;
    padding-bottom: $contentPadding / 2;
  }

  > .userheader > .optionmenu {
    align-items: center;
    display: flex;
  }
}

/*-- Content user header --*/

.post-block > .content > .userheader {
  background-color: $whitey;

  .date {
    color: $warmGrey;
    font-weight: 300;
  }
}

/*-- Content header --*/

$borderColour: #ededed;
.post-block > .content > .header {
  align-items: center;
  background-color: white;
  border-bottom: 2px solid $borderColour;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0.5em;

  > .actions,
  > .actions > .button {
    margin: 0;
  }

  > .personalrecord {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  > .personalrecord > .flexpit-svg-icon {
    margin-right: 0.66em;
  }
}

/*-- Content body --*/

.post-block > .content > .body {
  overflow-y: auto;
  overflow-x: hidden;

  > .activity {
    text-transform: capitalize;
  }
}

/*-- Content add comment form --*/

.post-block > .content > .addcomment {
  border-top: 2px solid $borderColour;
}

/*------------------
    Post grid
--------------------*/

@mixin set-post-margin($postGridM: 40px) {
  > .grid-container > .row {
    margin-bottom: $postGridM;
    will-change: margin-bottom;
    transition: 0.2s margin-bottom;
  }

  .post-grid-item {
    margin-right: $postGridM;
    will-change: margin-right;
    transition: 0.2s margin-right;
  }
}

.post-grid {
  @include set-post-margin();

  .post-grid-item:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  $postGridM: 5px;

  .post-grid {
    @include set-post-margin(5px);
  }
}

.post-grid-item {
  position: relative;

  > .post-card {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.post-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  > .overlay {
    align-items: center;
    background: transparentize($daringBlue, 0.19);
    color: white;
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    justify-content: space-between;
    opacity: 0;
    padding: 7.5%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    text-transform: capitalize;
    transition: 0.2s opacity;
    visibility: hidden;
  }

  &:hover > .overlay,
  &:focus > .overlay {
    opacity: 1;
    visibility: visible;
  }

  .name {
    margin-bottom: 0;
  }

  .repetitions {
    font-family: Nunito;
  }

  > .icon {
    z-index: 5;
  }
}

/*------------------
    Post list
--------------------*/

.post-list {
  @include reset-list;

  > .post-item {
    margin-bottom: 40px;
  }
}

.post-item {
  $maxHeightPost: 475px;

  @include soft-elevated-shadow;
  position: relative;
  max-width: $maxHeightPost * 2;

  > .post-block {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.scrolling-modal-container
  > .ui.modals.dimmer
  > .ui.modal.scrolling.post-modal {
  margin-top: auto !important;
}

.post-modal {
  position: relative;

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  > .content > .post-block {
    height: 100%;
  }
}
