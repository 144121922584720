.store-links-page {
  height: 100%;
  padding: 1.5em;
}

.store-links-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  > .flexpitlogo {
    margin-top: auto;
    margin-bottom: 3em;
    width: 250px;
  }

  > .welcometext {
    color: tint($textColor, 15%);
    font-family: $headerFont;
    font-size: 1.2em;
    margin-bottom: 2.3em;
    text-align: center;
  }

  > .badge-list {
    margin-top: auto;
    margin-bottom: 4em;
  }
}
