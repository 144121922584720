$itemSidePadding: 0.36em;

.activity-ranking-list {
  > .header {
    padding-left: $itemSidePadding !important;
    padding-right: $itemSidePadding !important;
  }
}

.activity-ranking-item {
  align-items: center;
  display: flex !important;
  flex-direction: row;
  padding: 0.57em $itemSidePadding !important;

  > .illustration {
    margin-right: 1em;
    width: 20px !important;
    flex: 0 0;
  }

  > .position {
    margin-left: auto;
    padding-left: $itemSidePadding + 0.29em;
    padding-right: 0.29em;
  }
}

.ui.table tr.group-header-row {
  background-color: #f1f1f1;
  font-family: $headerFont;

  > th {
    $border: 1px solid $silver;
    border-top: $border;
    border-bottom: $border;
    padding: 0.5em;
  }
}
