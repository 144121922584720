.content-container {
  &.-mini {
    max-width: 400px;
  }

  &.-tiny {
    max-width: 500px;
  }

  &.-small {
    max-width: 600px;
  }

  &.-medium {
    max-width: 700px;
  }

  &.-large {
    max-width: 800px;
  }

  &.-big {
    max-width: 900px;
  }

  &.-left,
  &.-centre {
    margin-right: auto;
  }

  &.-centre,
  &.-right {
    margin-left: auto;
  }
}
